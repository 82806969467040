import { Injectable } from '@angular/core';
import { MappedRequestTimeWindow } from '@core/interfaces/requests-time-windows.interface';
import {
  State, Selector, Action, StateContext,
} from '@ngxs/store';
import { tap, catchError, of } from 'rxjs';
import { RequestsTimeWindowsService } from '@core/services/requests-time-windows.service';
import { mapRequestTimeWindowData } from '@core/helper/request-time-window-helper';
import { GetListRequestTimeWindow } from '../action/requests-time-windows.action';

export interface RequestTimeWindowStateModel {
  requestTimeWindows: MappedRequestTimeWindow[];
  total: number | null;
  error: string | null;
}

@State<RequestTimeWindowStateModel>({
  name: 'requestTimeWindow',
  defaults: {
    requestTimeWindows: [],
    total: null,
    error: null,
  },
})
@Injectable()
export class RequestTimeWindowState {
  constructor(private requestsTimeWindowsService: RequestsTimeWindowsService) {}

  @Selector()
  static requestTimeWindows(state: RequestTimeWindowStateModel): any {
    return state.requestTimeWindows;
  }

  @Selector()
  static getTotalRequestTimeWindows(state: RequestTimeWindowStateModel): any {
    return state.total;
  }

  @Selector()
  static error(state: RequestTimeWindowStateModel): any {
    return state.error;
  }

  @Action(GetListRequestTimeWindow)
  getListRequestTimeWindow(ctx: StateContext<RequestTimeWindowStateModel>, { params }: GetListRequestTimeWindow): any {
    ctx.patchState({ error: null });
    return this.requestsTimeWindowsService.getListRequestTimeWindow(params).pipe(
      tap((result) => {
        const requestTimeWindows = result.data.getListTimeSlotsEstimation.result;

        const mappedData = mapRequestTimeWindowData(requestTimeWindows);

        ctx.patchState({
          requestTimeWindows: mappedData,
        });

        ctx.patchState({
          total: result.data.getListTimeSlotsEstimation.total,
        });
      }),
      catchError((error) => {
        ctx.patchState({ error: error.message });
        return of(null);
      }),
    );
  }
}
