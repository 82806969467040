/* eslint-disable */

import { GetListParams, SortInterface } from '@core/interfaces/get-list.interface';
import { RequestTimeWindowFilterDto } from '@core/interfaces/requests-time-windows.interface';
import { LocationFilter } from '@core/interfaces/time-window.interface';
import { OffsetPagination } from '@core/interfaces/zone.interface';

export class GetListRequestTimeWindow {
  static readonly type = '[RequestTimeWindow] Get List';
  constructor(public params: GetListParams<LocationFilter>) {}
}
